/*!

=========================================================
* Vue Argon Design System - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system/blob/master/LICENSE.md)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from "vue";
import App from "./App.vue";
import router_main from "./router";
import router_org from "./router-org";
import Argon from "./plugins/argon-kit";
import './registerServiceWorker'
import store from "./store";
import i18n from "./plugins/i18n";
import VueStarRating from "vue-star-rating";
import VueNumberFormat from 'vue-number-format'

// import { CHECK_AUTH } from "./store/actions.type";
import ApiService from "./common/api.service";
import DateFilter from "./common/date.filter";
import ErrorFilter from "./common/error.filter";
import VueMeta from 'vue-meta'
import moment from 'moment';
import VueFab from 'vue-float-action-button'

Vue.use(VueFab)
Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('HH:mm, DD.MM.YYYY')
  }
});
Vue.use(VueNumberFormat, { prefix: 'R$ ', decimal: ',', thounsand: '.' })
Vue.config.productionTip = false;
Vue.filter("date", DateFilter);
Vue.filter("error", ErrorFilter);

ApiService.init();

// // Ensure we checked auth before each page load.
// router.beforeEach((to, from, next) =>
//     Promise.all([store.dispatch(CHECK_AUTH)]).then(next)
// );

Vue.config.productionTip = false;
Vue.use(Argon);
Vue.use(VueStarRating)
Vue.use(VueMeta)


const host = window.location.host;
const parts = host.split('.');
const domainLength = 3; // route1.example.com => domain length = 3

const routers = (() => {
  var routes = router_org
  if (parts.length === (domainLength - 1) || parts[0] === 'www' || host.includes("localhost")) {
    routes = router_main;
  }
  return routes;
});

/* eslint-disable no-new */
new Vue({
  i18n,
  store,
  el: '#app',
  router: routers(),
  render: h => h(App)
})
